<template>
  <a-form :ref="formRef" :label-col="labelCol" :wrapper-col="wrapperCol">
    <div>
      <!-- <a-form :form="addTeamMemberForm"> -->

      <div class="create-company-created-by-wrapper">
        <div class="create-company-created-by-text">Create New User</div>
        <div class="create-company-created-by-sub-div-wrapper">
          <div class="employement-and-department-wrapper">
            <!-- <div class="form-sub-section">
              <div class="add-candidate-form-elements left-form-elements">
                <a-form-item>
                  <span class="input-label">Level</span>
                  <a-input
                    class="form-input"
                    placeholder="First Name"
                  ></a-input>
                </a-form-item>
              </div>
              <div class="add-candidate-form-elements">
                <a-form-item>
                  <span class="input-label">Current Exp</span>
                  <a-input class="form-input"></a-input>
                </a-form-item>
              </div>
            </div> -->
            <div class="employee-name-wrapper">
              <div class="basic-details-fields-labels">
                First Name<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.firstName">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  v-model:value="modelRef.firstName"
                />
              </a-form-item>
            </div>
            <div class="department-wrapper">
              <div class="basic-details-fields-labels">
                Last Name<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.lastName">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  v-model:value="modelRef.lastName"
                />
              </a-form-item>
            </div>
          </div>

          <div class="team-name-and-sub-team-wrapper">
            <div class="team-name-wrapper">
              <div class="basic-details-fields-labels">
                Site<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.siteId">
                <a-select
                  class="select-input"
                  v-model:value="modelRef.siteId"
                  style="width: 100%"
                  placeholder="Select"
                  :options="siteOptions"
                  @change="handleChange"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="basic-details-fields-labels">
                Designation<span class="all-required-fields-star-mark">*</span>
              </div>
             <a-form-item v-bind="validateInfos.designationId">
                <a-select
                  class="select-input"
                  v-model:value="modelRef.designationId"
                  style="width: 100%"
                  placeholder="Select"
                  :options="designationOptions"
                  @change="handleChange"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>

          <div class="date-of-creation-and-rm-wrapper">
            <div class="date-of-creation-wrapper">
              <div class="basic-details-fields-labels">
                Team<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.teams">
                <a-select
                  class="select-input"
                  v-model:value="modelRef.teams"
                  mode="tags"
                  style="width: 100%"
                  placeholder="Select"
                  :options="options"
                  @change="handleChange"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>

          <div class="date-of-creation-and-rm-wrapper">
            <div class="date-of-creation-wrapper">
              <div class="basic-details-fields-labels">
                Sub Team<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.subTeams">
                <a-select
                  class="select-input"
                  v-model:value="modelRef.subTeams"
                  mode="tags"
                  style="width: 100%"
                  placeholder="Select"
                  :options="subTeamOptions"
                  @change="handleChange"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>
        </div>
      </div>

      <div class="create-company-created-by-wrapper">
        <div class="create-company-created-by-sub-div-wrapper">
          <div class="create-company-created-by-text-2">User Details</div>

          <div class="employement-and-department-wrapper">
            <div class="employee-name-wrapper">
              <div class="basic-details-fields-labels">
                Mobile<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.mobile">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  type="number"
                  v-model:value="modelRef.mobile"
                />
              </a-form-item>
            </div>
            <div class="department-wrapper">
              <div class="basic-details-fields-labels">
                Phone<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.phone">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  type="number"
                  v-model:value="modelRef.phone"
                />
              </a-form-item>
            </div>
          </div>

          <div class="department-wrapper">
            <div class="basic-details-fields-labels">
              Salary(INR p.m)<span class="all-required-fields-star-mark"
                >*</span
              >
            </div>

            <a-form-item v-bind="validateInfos.salary">
              <a-input
                style="width: 100%"
                class="employee-name-input-field"
                placeholder="Enter"
                type="number"
                v-model:value="modelRef.salary"
              />
            </a-form-item>
          </div>
        </div>
      </div>

      <div class="create-company-created-by-wrapper">
        <div class="create-company-created-by-sub-div-wrapper">
          <div class="create-company-created-by-text-2">Login Details</div>

          <div class="employement-and-department-wrapper">
            <div class="employee-name-wrapper">
              <div class="basic-details-fields-labels">
                Name<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.loginName">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  v-model:value="modelRef.loginName"
                />
              </a-form-item>
            </div>
            <div class="department-wrapper">
              <div class="basic-details-fields-labels">
                Alias<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.loginAlias">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  v-model:value="modelRef.loginAlias"
                />
              </a-form-item>
            </div>
          </div>

          <div class="team-name-and-sub-team-wrapper">
            <div class="team-name-wrapper">
              <div class="basic-details-fields-labels">
                View<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item>
                <a-select
                  class="select-input"
                  v-bind="validateInfos.loginView"
                  style="width: 100%"
                  placeholder="Select"
                  :options="viewOptions"
                  v-model:value="modelRef.loginView"
                  @change="handleChange"
                >
                </a-select>
              </a-form-item>
            </div>
            <div class="sub-team-name-wrapper">
              <div class="basic-details-fields-labels">
                Role<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item>
                <!-- <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                /> -->
                <a-select
                  class="select-input"
                  v-model:value="modelRef.roleId"
                  style="width: 100%"
                  placeholder="Select"
                  :options="rolesOptions"
                  @change="handleChange"
                >
                </a-select>
              </a-form-item>
            </div>
          </div>

          <div class="employement-and-department-wrapper">
            <div class="employee-name-wrapper">
              <div class="basic-details-fields-labels">
                Email<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.email">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  v-model:value="modelRef.email"
                />
              </a-form-item>
            </div>
            <div class="department-wrapper">
              <div class="basic-details-fields-labels">
                Password<span class="all-required-fields-star-mark">*</span>
              </div>
              <a-form-item v-bind="validateInfos.password">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  type="password"
                  v-model:value="modelRef.password"
                />
              </a-form-item>
            </div>
          </div>

          <div class="employement-and-department-wrapper">
            <div class="employee-name-wrapper">
              <div class="basic-details-fields-labels">
                Re Type Password<span class="all-required-fields-star-mark"
                  >*</span
                >
              </div>
              <a-form-item v-bind="validateInfos.repassword">
                <a-input
                  class="employee-name-input-field"
                  placeholder="Enter"
                  type="password"
                />
              </a-form-item>
            </div>
            <div class="department-wrapper">
              <div class="basic-details-fields-labels">
                Date of Joining<span class="all-required-fields-star-mark"
                  >*</span
                >
              </div>
              <a-form-item v-bind="validateInfos.joiningDate"
                ><a-date-picker
                  class="date-input"
                  v-model:value="modelRef.joiningDate"
              /></a-form-item>
            </div>
          </div>
        </div>
      </div>

      <div class="create-company-created-by-wrapper">
        <div class="create-company-created-by-sub-div-wrapper">
          <div class="create-company-created-by-text-2">Access Details</div>
          <div class="allowed-login-text">Login Allowed On:</div>
          <div class="employement-and-department-days-wrapper">
            <div style="padding-top: 10px">
              <a-form-item>
                <a-row style="display: flex">
                  <div
                    @click="
                      modelRef.acl.days.sunday = !modelRef.acl.days.sunday
                    "
                    style="margin: 0 20px 0 0"
                    :class="
                      modelRef.acl.days.sunday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    S
                  </div>
                  <div
                    @click="
                      modelRef.acl.days.monday = !modelRef.acl.days.monday
                    "
                    style="margin: 0 20px"
                    :class="
                      modelRef.acl.days.monday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    M
                  </div>
                  <div
                    @click="
                      modelRef.acl.days.tuesday = !modelRef.acl.days.tuesday
                    "
                    style="margin: 0 20px"
                    :class="
                      modelRef.acl.days.tuesday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    T
                  </div>
                  <div
                    @click="
                      modelRef.acl.days.wednesday = !modelRef.acl.days.wednesday
                    "
                    style="margin: 0 20px"
                    :class="
                      modelRef.acl.days.wednesday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    W
                  </div>
                  <div
                    @click="
                      modelRef.acl.days.thursday = !modelRef.acl.days.thursday
                    "
                    style="margin: 0 20px"
                    :class="
                      modelRef.acl.days.thursday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    T
                  </div>
                  <div
                    @click="
                      modelRef.acl.days.friday = !modelRef.acl.days.friday
                    "
                    style="margin: 0 20px"
                    :class="
                      modelRef.acl.days.friday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    F
                  </div>
                  <div
                    @click="
                      modelRef.acl.days.saturday = !modelRef.acl.days.saturday
                    "
                    style="margin: 0 20px"
                    :class="
                      modelRef.acl.days.saturday
                        ? 'days-circle-selected'
                        : 'days-circle-unselected'
                    "
                  >
                    S
                  </div>
                </a-row>
              </a-form-item>
            </div>
            <div style="display: flex">
              <span style="color: #f5222d" v-if="DaysErrorMsg">{{
                DaysErrorMsg
              }}</span>
            </div>
          </div>

          <div style="display: flex; justify-content: space-between">
            <div style="width: 20%; display: flex; align-items: center">
              <div>Time In:</div>
              <div style="width: 40%; padding: 5px">
                <a-select
                  v-bind="validateInfos.startTime"
                  size="large"
                  placeholder="Please select"
                  style="width: 100%"
                  class="select-input"
                  @change="handleChange"
                  v-model:value="modelRef.acl.startTime"
                  :options="timeOptions"
                >
                  <!-- <a-option v-for="option in timeOptions" :key="option">
                  {{ option }}
                </a-option>-->
                </a-select>
              </div>
              <div>Hrs</div>
            </div>

            <div style="width: 20%; display: flex; align-items: center">
              <div>Time In:</div>
              <div style="width: 40%; padding: 5px">
                <a-select
                  v-bind="validateInfos.endTime"
                  size="large"
                  placeholder="Please select"
                  style="width: 100%"
                  class="select-input"
                  @change="handleChange"
                  v-model:value="modelRef.acl.endTime"
                  :options="timeOptions"
                >
                  <!-- <a-option v-for="option in timeOptions" :key="option">
                  {{ option }}
                </a-option>-->
                </a-select>
              </div>
              <div>Hrs</div>
            </div>
            <div style="display: flex">
              <div style="padding: 10px 5px">Valid Till:</div>
              <a-form-item v-bind="validateInfos.validity"
                ><a-date-picker
                  class="date-input"
                  v-model:value="modelRef.acl.validity"
              /></a-form-item>
            </div>
          </div>
          <div class="create-team-member-note">
            Note: For no restriction on time set Time in =0= Time out
          </div>
          <div
            style="
              display: flex;
              justify-content: space-evenly;
              margin-top: 30px;
            "
          >
            <div>
              <a-checkbox
                v-bind="validateInfos.isMacCheckEnabled"
                v-model:checked="modelRef.acl.isMacCheckEnabled"
                >Mac check</a-checkbox
              >
            </div>
            <div>
              <a-checkbox 
                v-bind="validateInfos.isIpCheckEnabled"
                v-model:checked="modelRef.acl.isIpCheckEnabled"
                >IP check</a-checkbox
              >
            </div>
          </div>
        </div>

        <div style="text-align: center; margin-top: 30px">
          <a-button
            class="create-new-account-create-btn"
            @click="onSubmit"
            type="primary"
          >
            Create User
          </a-button>
        </div>
      </div>
      <!-- </a-form> -->
      <!-- <div style="create-company-created-by-wrapper">
      <div class="create-company-created-by-sub-div-wrapper">
        <div class="create-company-created-by-text-2">Access Details</div>
        <div>
          <span>Login Allowed on:</span>
        </div>
        <div>
          <div>
            <div>
              <div style="padding-top: 10px">
                <a-form-item>
                  <a-row style="display: flex">
                    <div
                      @click="addDayToWeek('', 'sunday')"
                      style="margin: 0 5px 0 0"
                      :class="
                        days.sunday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      S
                    </div>
                    <div
                      @click="addDayToWeek('', 'monday')"
                      style="margin: 0 5px"
                      :class="
                        days.monday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      M
                    </div>
                    <div
                      @click="addDayToWeek('', 'tuesday')"
                      style="margin: 0 5px"
                      :class="
                        days.tuesday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      T
                    </div>
                    <div
                      @click="addDayToWeek('', 'wednesday')"
                      style="margin: 0 5px"
                      :class="
                        days.wednesday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      W
                    </div>
                    <div
                      @click="addDayToWeek('', 'thursday')"
                      style="margin: 0 5px"
                      :class="
                        days.thursday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      T
                    </div>
                    <div
                      @click="addDayToWeek('', 'friday')"
                      style="margin: 0 5px"
                      :class="
                        days.friday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      F
                    </div>
                    <div
                      @click="addDayToWeek('', 'saturday')"
                      style="margin: 0 5px"
                      :class="
                        days.saturday
                          ? 'days-circle-selected'
                          : 'days-circle-unselected'
                      "
                    >
                      S
                    </div>
                  </a-row>
                </a-form-item>
              </div>
              <div style="display: flex">
                <span style="color: #f5222d" v-if="DaysErrorMsg">{{
                  DaysErrorMsg
                }}</span>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
    <!-- <a-form-item label="Activity name" v-bind="validateInfos.name">
      <a-input
        v-model:value="modelRef.name"
        @blur="validate('name', { trigger: 'blur' }).catch(() => {})"
      />
    </a-form-item>
    <a-form-item label="Activity zone" v-bind="validateInfos.region">
      <a-select v-model:value="modelRef.region" placeholder="please select your zone">
        <a-select-option value="shanghai">Zone one</a-select-option>
        <a-select-option value="beijing">Zone two</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click.prevent="onSubmit">Create</a-button>
      <a-button style="margin-left: 10px" @click="resetFields">Reset</a-button>
    </a-form-item> -->
  </a-form>
</template>
<script>
import { defineComponent, reactive, toRaw, ref, onMounted } from "vue";
import { Form , message} from "ant-design-vue";
import Services from "@/services/apis";
import { useRouter } from "vue-router";
import moment from "moment";

// const timeOptions = [
//   "1",
//   "2",
//   "3",
//   "4",
//   "5",
//   "6",
//   "7",
//   "8",
//   "9",
//   "10",
//   "11",
//   "12",
//   "13",
//   "14",
//   "15",
//   "16",
//   "17",
//   "18",
//   "19",
//   "20",
//   "21",
//   "22",
//   "23",
//   "24",
// ];
const timeOptions = [...Array(24)].map((_, i) => ({
  value: i + 1,
}));

const viewOptions = [
  { value: "COMPANY", label: "Company" },
  { value: "SITE", label: "Site" },
  { value: "TEAM", label: "Team" },
  { value: "CIC", label: "CIC" },
];

const useForm = Form.useForm;
export default defineComponent({
  setup() {
    const router = useRouter();
    const formRef = ref();
    const modelRef = reactive({
      firstName: "",
      lastName: "",
      mobile: null,
      phone: null,
      email: "",
      salary: null,
      loginName: "",
      loginAlias: "",
      loginView: ref([]),
      password: "",
      type: "MEMBER",
      teams: ref([]),
      siteId: ref([]),
      subTeams: ref([]),
      roleId: ref([]),
      designationId: ref([]),
      joiningDate: "",
      acl: {
        days: {
          sunday: true,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
        },
        startTime: "",
        endTime: "",
        validity: "",
        isMacCheckEnabled: false,
        isIpCheckEnabled: false,
      },
    });

    let options = ref([]);
    let rolesOptions = ref([]);
    let subTeamOptions = ref([]);
    let siteOptions = ref([]);
    let designationOptions = ref([]);

    const handleChange = (value) => {
      console.log(`selected time  ${value}`, modelRef.acl);
    };
    const rulesRef = reactive({
      // password: [
      //   {
      //     required: true,
      //     min: 3,
      //     max: 5,
      //     message: "Length should be 5 to 8",
      //     trigger: "change",
      //   },
      // ],
      // repassword: [
      //   {
      //     required: true,
      //     min: 3,
      //     max: 5,
      //     message: "Length should be 5 to 8",
      //     trigger: "change",
      //   },
      // ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );

    let validatePass = async (rule, value) => {
      if (value === "") {
        return Promise.reject("Please input the password");
      } else {
        if (modelRef.repassword !== "") {
          formRef.value.validateFields("repassword");
        }
        return Promise.resolve();
      }
    };
    let validatePass2 = async (rule, value) => {
      if (modelRef.password === "") {
        return Promise.reject("Please input the password again");
      } else if (modelRef.password !== modelRef.repassword) {
        return Promise.reject("Two inputs don't match!");
      } else {
        return Promise.resolve();
      }
    };

    function validatePassword() {
      if (modelRef.password != modelRef.repassword) {
        modelRef.repassword.setCustomValidity("Passwords Don't Match");
      } else {
        modelRef.repassword.setCustomValidity("");
      }
    }

    // password.onchange = validatePassword;
    // confirm_password.onkeyup = validatePassword;

    const onSubmit = () => {
      console.log("modelref :>> ", modelRef);
      validate()
        .then((result) => {
          modelRef.salary = parseInt(modelRef.salary);
          modelRef.acl.startTime = parseInt(modelRef.acl.startTime);
          modelRef.acl.endTime = parseInt(modelRef.acl.endTime);
          modelRef.roleId = modelRef.roleId.toString();
          modelRef.siteId = modelRef.siteId.toString();
          modelRef.loginView = modelRef.loginView.toString();
          modelRef.designationId = modelRef.designationId.toString();

          let days = [];
          for (var i in modelRef.acl.days) {
            if (modelRef.acl.days[i] === true) {
              days.push(i.toUpperCase());
            }
          }

          modelRef.acl.days = days;

          //  let view = [];
          // for (var a in modelRef.acl.loginView) {
          //   if (modelRef.acl.loginView[a]) {
          //     view.push(a.toUpperCase());
          //   }
          // }
          //   console.log('modelRef.acl.loginView :>> ', modelRef.acl.loginView);
          // modelRef.acl.loginView = view;

          console.log('coming 1 :>> ');

          Services.createTeamMember(modelRef).then((response) => {
            const { data } = response;

             message.success("Team member created successfully");
            console.log('coming 2 :>> ');
            router.push(`/master`);
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    //   const success = () => {
    //     message.success('This is a success message');

    // };

    onMounted(() => {
      let payload = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
        types: ["TEAM"],
      };
      Services.teamListing(payload)
        .then((response) => {
          const { data } = response;
          options.value = data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      let payloadRoles = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
      };
      console.log("testing.,,");
      Services.rolesListing(payloadRoles)
        .then((response) => {
          // const { data } = response;

          rolesOptions.value = response.data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
          console.log(response);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      let payloadSubTeam = {
        q: "",
        pageNo: 1,
        mode: "LISTING",
        type: ["SUB_TEAM"],
      };
      console.log("testing.,,");
      Services.teamListing(payloadSubTeam)
        .then((response) => {
          // const { data } = response;

          subTeamOptions.value = response.data.data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
          console.log(response);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

      let payloadSite = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
      };
      console.log("testing.,,");
      Services.sitesListing(payloadSite)
        .then((response) => {
          // const { data } = response;

          siteOptions.value = response.data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
          console.log("Site response", response);
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

       let designationPayload = {
        q: "",
        pageNo: 0,
        mode: "LISTING",
        types: ["DESIGNATIONS"],
      };
      Services.designationListing(designationPayload)
        .then((response) => {
          const { data } = response;
          designationOptions.value = data[0].data.map(
            ({ id: value, name: label, ...rest }) => ({
              value,
              label,
              ...rest,
            })
          );
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });

  
      // Temporary => To Call Multiple API on mounted see below example
      // Services.teamListing(payload)
      // .then((response) => {
      //   const { data } = response;
      // })
      // .catch((e) => {
      //   console.log("error :>> ", e);
      // });
    });

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      validate,
      validateInfos,
      resetFields,
      modelRef,
      formRef,
      addSchedule: [],
      DaysErrorMsg: "",
      // value1,
      moment,
      onSubmit,
      handleChange,
      options,
      timeOptions,
      rolesOptions,
      subTeamOptions,
      siteOptions,
      viewOptions,
      designationOptions,
    };
  },
  mounted() {
    // this.getTeamDetails();
  },
  methods: {
    // validateToNextAccountNumber(rule, value, callback) {
    //   const form = this.addOrganizationForm;

    //   if (value && value !== form.getFieldValue("password")) {
    //     callback("Your account number and re-account number does not match.");
    //   } else {
    //     callback();
    //   }
    // },
    addDayToWeek(arrayIndex, day) {
      if (arrayIndex.length === 0) {
        //  this.DaysErrorMsg = "Please select at least one day.";
        this.days[day] = !this.days[day];
      } else {
        this.addSchedule.map((x, index) => {
          if (index === arrayIndex) {
            this.addSchedule[arrayIndex].days[day] =
              !this.addSchedule[arrayIndex].days[day];
          }
        });
      }
      if (this.checkAtLeastOneDaySelected()) {
        this.DaysErrorMsg = "Please select at least one day.";
        return false;
      } else {
        this.DaysErrorMsg = "";
      }
    },
    checkAtLeastOneDaySelected() {
      let selectedDay = Object.keys(this.days).every((k) => !this.days[k]);
      return selectedDay;
    },

    addTeamMember() {
      console.log("TEAM ....", toRaw(modelRef));
      Services.createTeamMember(payload)
        .then((response) => {
          const { data } = response;
          console.log("ADD Team Member data :>> ", data);

          // data &&
          //   data.length &&
          //   data[0].candidates.map((x) => {
          //     this.candidatesData.push({
          //       icon: x.imageUrl,
          //       serialNo: +1,
          //       candidateName: {
          //         name: x.name,
          //         resumeId: x.resumeId,
          //         experience: x.experience,
          //         socialAccounts: x.socialAccounts,
          //       },
          //       education: x.education,
          //       testName: [x.practiceArea, x.subPracticeArea],
          //       notes: x.notesCount,
          //       twCount: x.totalWorkExp,
          //       docCount: x.documentsCount,
          //       ctc: x.ctc,
          //       comments: x.comments,
          //       status: x.status,
          //       cic: x.cic,
          //     });
          //     this.allBookingsCount = data[0].count;

          //     this.paginationValues = {
          //       total: data[0].count,
          //       current: this.scrollIncrement,
          //     };
          //     this.loading = false;
          //   });
        })
        .catch((e) => {
          console.log("error :>> ", e);
        });
    },
  },
});

function getTeamDetails(param) {
  console.log("param :>> ", param);
  let payload = {
    q: "",
    pageNo: 1,
    mode: "LISTING",
    types: ["TEAM"],
  };
  console.log("testing.,,");
  Services.teamListing(payload)
    .then((response) => {
      const { data } = response;
      param = data.data.map(({ id: value, name: label, ...rest }) => ({
        value,
        label,
        ...rest,
      }));
      console.log("this.options :>> ", param);

      return param;
    })
    .catch((e) => {
      console.log("error :>> ", e);
    });
}
</script>

<style lang="scss" scoped>
@import "../styles/addTeamMember.scss";
</style>